
.menumap {
  position: fixed;
  top: 25%;
  left: 50%;
  margin-left: -50vh;
  z-index: 500;
  height: 50%;
  width: auto;
  transform: rotate(5deg)
}

.menumap > img {
  height: 100%;
  width: auto;
}

.menu-wrapper {
  position: absolute;
  top: 0px;
}

.email-container, .email-container > a {
  position: fixed;
  bottom: 50px;
  right: 50px;
  padding: 5px;
  color: white;
  text-decoration: none;
  background: #000;
  height: 3rem;
  font-size: 1.25rem;
  padding: .75rem;
  box-sizing: border-box;
  z-index: 501;
}
.email-container > a:hover {
  /*text-decoration: underline wavy;*/
  color: rgba(255,255,255,0.7);
}

.background-home-front {
  z-index: 501;
}

.background-home-front::after {
  content: "";
  background: url(./background-front.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 501;
  pointer-events: none;
}

.background-home {
  z-index: -2;
  height: 100vh;
  width: 100vw;
  background-image: url('./background.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.menu-link-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  top: -100%;
  z-index: 99999;
}

.somethingelse {
  color: red;
}

.image-link {
  width: 78%;
  height: 6.6%;
  position: absolute;
  transition: 0.65s;
  z-index: 1050;
  box-sizing: border-box; 
}

.image-link:hover {
  background: rgba(0,0,0,.3);
}

.background-image {
  width: 40vw;
  display: flex;
  position: absolute;
  top: calc(50vh - 20vw);
  left: calc(30vw);
  height: auto;
  margin: auto;
  z-index: -999;
  background: #FFF;
  -webkit-box-shadow: 4px 5px 7px 2px #000000; 
  box-shadow: 4px 5px 7px 2px #000;
}


.title-container  {
  position: fixed;
  top: 3em;
  background: url(title-background.png);
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  min-width: 200px;
}

.play-pause-btn {
  z-index: 99999;
}

.audio-container {
  width: inherit !important;
  display: block;
  width: inherit;
  margin-top: 3rem;
  color: #000000;
  height: 50px;
}

.music-page-header {
  color: rgba(255,255,255,0.9);
  font-family: 'Palette Mosaic', cursive;
  width: 100%;
  text-align: center;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  flex: 1 auto;
  background-color: transparent;
  min-width: 500px;
}

.music-page-container {
  width: 40vw;
  display: flex;
  position: absolute;
  top: calc(50vh - 20vw);
  left: calc(30vw);
  height: 40vw;
  margin: auto;
  z-index: 99 !important;
  background: #FFF;
  -webkit-box-shadow: 4px 5px 7px 2px #000000; 
  box-shadow: 4px 5px 7px 2px #000;
  text-align: center;
  padding: 50px;
  box-sizing: border-box;
  background-size: 100%;
}

.full-screen-background-music {
  background: url(./song_background.png);
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.play-pause-btn {
  background-color: transparent;
  border: none;
}


.play-pause-btn > img {
  -webkit-filter: invert(100%) drop-shadow(0 0 0.75rem rgba(0,0,0,0.6));
  filter: invert(100%) drop-shadow(0 0 0.75rem rgba(0,0,0,0.6));
  width: 120px;
  height: 120px;
  position: fixed !important; 
  top: calc(50vh - 60px);
  left: calc(50vw - 60px);
  transition: 0.2s;
}

.play-pause-btn > img:hover {
  -webkit-filter: invert(100%) drop-shadow(0 0 1rem rgba(0,0,0,0.9));;
  filter: invert(100%) drop-shadow(0 0 1rem rgba(0,0,0,0.9));
  width: 128px;
  height: 128px;
  margin-left: -4px;
  margin-top: -4px;
}

.paused {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

.home-btn {
  position: absolute;
  top: 50px;
  left: 50px;
  width: auto;
  background: #000;
  height: 3rem;
  font-size: 1.25rem;
  padding: .75rem;
  box-sizing: border-box;
  transition: 0.65s;
}

.on-home-btn {
  top: -3.1rem !important;
}

.home-btn > a {
  padding: 5px;
  color: white;
  text-decoration: none;

}

.home-btn > a:hover {
  color: rgba(255,255,255,0.7);
  /*text-decoration: underline wavy;*/
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}